export class Radiobutton {

  public label: string;
  public selected: boolean;
  public disabled: boolean;
  public description: string;
  public subDescription: string;
  public id: number|string;
  public altId: number|string;
  public planDuration: string;
  public category: number;
  public planPrice: string;

  constructor(label: string, selected = false, disabled = false, description?, id?, subDescription?, altId?, planDuration?, category?) {
    this.label = label;
    this.selected = selected;
    this.disabled = disabled;
    this.description = description;
    this.subDescription = subDescription;
    this.planPrice = subDescription;
    this.planDuration = planDuration;
    this.id = id;
    this.altId = altId;
    this.category = category;
  }

  getPaymentDuration(): string {
    if (typeof this.id === 'string' && this.category === 2) {
      switch (this.planDuration) {
        case 'month':
          return $localize`Monthly Pass`;
        case 'day':
          return $localize`Daily Pass`;
        case 'week':
          return $localize`Weekly Pass`;
        default:
          return $localize`Event Pass`;
      }
    }
    switch (true) {
      case (this.planDuration === 'year'):
        return $localize`Annually`;
      case (this.planDuration === 'month'):
        return $localize`Monthly`;
      case (this.planDuration === 'week'):
        return $localize`Weekly`;
      case (this.planDuration === 'day'):
        return $localize`Daily`;
      default:
        return this.planDuration.slice(0, 1).toUpperCase() + this.planDuration.slice(1);
    }
  }
}
